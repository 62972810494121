$marginMd: 16px;

.ObjectSurveillance {

    .section {
        margin: 64px 0;
        position: relative;
        color: rgb(15, 27, 50);

        .text {
            font-size: 24px;
        }

        .paragraph {
            font-weight: bold;
        }

        .bgImgBox {
            width: 50vw;
            height: 300px;
            position: relative;

            .bgImg {
                object-fit: contain;
                position: absolute;
                max-height: 100%;
            }

            .img1 {
                width: 25%;
                top: $marginMd;
                right: 40%;
            }

            .img2 {
                width: 25%;
                top: 60px;
                left: 50px;
            }

            .img3 {
                width: 50%;
                bottom: $marginMd;
                right: $marginMd;
            }
        }

        .text {
            width: 50vw;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .title {
            font-weight: bold;
            text-align: center;
        }

        @media only screen and (max-width: 600px) {
            .bgImgBox {
                width: 100vw;
                height: 300px;
                position: relative;

                .bgImg {
                    object-fit: contain;
                    position: absolute;
                }

                .img1 {
                    width: 30%;
                    top: $marginMd;
                    right: 40px;
                }

                .img2 {
                    width: 30%;
                    top: 60px;
                    left: 50px;
                }

                .img3 {
                    width: 50%;
                    bottom: $marginMd;
                    right: $marginMd;
                }
            }

            .text {
                font-size: 24px;
                width: auto;
            }

            .title {
                font-weight: bold;
                text-align: center;
            }
        }
    }
}