.Navbar {
    * {
        text-decoration: none;
        color: rgba(0, 0, 0, 0.8);
    }

    position: fixed;
    top: 0;
    right: 0;

    .topBar {
        background: -webkit-linear-gradient(to bottom, rgba(255, 255, 255, 0.3) 90px, rgba(255, 255, 255, 0));
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0.3) 90px, rgba(255, 255, 255, 0));
        width: 100vw;
        height: 100px;

        .logoBox {
            position: absolute;
            top: 0;
            left: 0;
            height: 100px;
            padding: 24px;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;

            .logo {
                color: rgb(15, 27, 50);
                font-size: 24px;
                font-weight: bold;
            }
        }
    }

    .overlay {
        width: 100%;
        height: 100vh;
        opacity: 0.3;
        background-color: black;
        transition: width 0.01s;
        transition: opacity 0.25s ease-in-out;
    }

    .itemsBox {
        width: 300px;
        height: 100vh;
        position: absolute;
        top: 0;
        right: 0;
        padding-top: 100px;
        background-color: white;
        transition: all 0.25s ease-in-out;

        .navItem {
            display: block;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 28px;
            font-weight: bold;
            padding: 16px;

            .sub {
                display: block;
                font-size: 16px;
            }
        }

    }

    &.closed {
        .itemsBox {
            width: 0;
        }

        .overlay {
            width: 0;
            height: 0;
            opacity: 0;
        }
    }
}