$marginMd: 16px;

.Challenge {
    .section {
        margin: 64px 0;

        .bgImgBox {
            width: 50vw;
            height: 300px;
            position: relative;

            .bgImg {
                object-fit: contain;
                position: absolute;
                max-height: 100%;
            }

            .img1 {
                width: 30%;
                top: $marginMd;
                right: 40px;
            }

            .img2 {
                width: 30%;
                top: 20px;
                left: 50px;
            }

            .img3 {
                width: 50%;
                bottom: $marginMd;
                right: $marginMd;
            }

            .img4 {
                width: 50%;
                bottom: $marginMd;
                left: $marginMd;
            }
        }

        .text {
            width: 50vw;
            font-size: 24px;
            padding: $marginMd;
            color: rgb(15, 27, 50);
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        @media only screen and (max-width: 600px) {
            .bgImgBox {
                width: 100vw;
                position: relative;
            }

            .text {
                width: auto;
                font-size: 24px;
                text-align: center;
            }
        }
    }
}