.Auspruch {
    .section {
        position: relative;

        .bgImg {
            height: 80vh;
            width: 100%;
            object-fit: cover;
            object-position: 50% 0%;
            filter: brightness(50%);
            z-index: -1;
        }

        .text {
            font-size: 36px;
            // font-weight: bold;
            text-align: center;
            color: white;
            position: absolute;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
            width: 100%;
            white-space: nowrap;
            // background-color: rgba(0,0,0,0.3);
        }

        @media only screen and (max-width: 600px) {
            .text {
                font-size: 24px;
            }

        }
    }
}