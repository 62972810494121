.Header {
    position: relative;
    height: 100vh;
    width: 100%;
    overflow: hidden;
}

.bgImg {
    img {
        object-position: 40% 0%;
    }
}

.text {
    top: 50vh;
    left: auto;
    right: 0;
    padding: 16px;
    box-sizing: border-box;
    min-width: fit-content;
    width: 70vw;
    background: -webkit-linear-gradient(to left, rgba(0, 0, 0, 0.4) 40%, rgba(0, 0, 0, 0.2) 50%, rgba(0, 0, 0, 0.1) 60%, rgba(0, 0, 0, 0));
    background: linear-gradient(to left, rgba(0, 0, 0, 0.4) 40%, rgba(0, 0, 0, 0.2) 50%, rgba(0, 0, 0, 0.1) 60%, rgba(0, 0, 0, 0));

    display: flex;
    flex-direction: column;
    align-items: flex-end;

    * {
        text-align: end;
        color: white;
    }
}

.name {
    margin-bottom: 32px;
}

.myName {
    font-size: 36px;
    margin-bottom: 24px;
}

.subName {
    font-weight: bold;
    font-size: 36px;
}

.ourApproach {
    font-size: 24px;
}

.bgImg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;

    img {
        width: 100vw;
        height: 100%;
        object-fit: cover;
    }
}

.text {
    position: absolute;
    overflow: hidden;
}

.subName {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.subName1 {
    transform: translateY(0);
    animation: subName-appear 0.25s ease-in-out;

    @keyframes subName-appear {
        from {
            opacity: 0;
            transform: translateY(50vh);
        }

        to {
            opacity: 1;
            transform: translateY(0px);
        }
    }
}

.subName2 {
    display: flex;
    justify-content: space-between;
    max-width: fit-content;
}

.beratungs {
    transform: translateX(0);
    animation: beratungs-appear 1s ease-in-out;

    @keyframes beratungs-appear {
        0% {
            opacity: 0;
            transform: translateX(-150vw);
        }

        90% {
            opacity: 0;
            transform: translateX(-50vw);
        }

        100% {
            opacity: 1;
            transform: translateX(0px);
        }
    }
}

.und {
    margin: auto 1rem;
    transform: translateX(0);
    animation: und-appear 1.5s ease-in-out;

    @keyframes und-appear {
        0% {
            opacity: 0;
            transform: translateX(150vw);
        }

        90% {
            opacity: 0;
            transform: translateX(50vw);
        }

        100% {
            opacity: 1;
            transform: translateX(0px);
        }
    }
}

.dienstleistungen {
    transform: translateX(0);
    animation: dienstleistungen-appear 2s ease-in-out;

    @keyframes dienstleistungen-appear {
        0% {
            opacity: 0;
            transform: translateX(150vw);
        }

        90% {
            opacity: 0;
            transform: translateX(50vw);
        }

        100% {
            opacity: 1;
            transform: translateX(0px);
        }
    }
}


@media only screen and (max-width: 600px) {
    .bgImg {
        img {
            object-position: 40% 0%;
        }
    }

    .text {
        top: 50vh;
        left: 0;
        right: auto;
        width: 100vw;
        display: block;
    }

    .name {
        margin-bottom: 32px;
    }

    .myName {
        font-size: 24px;
        margin-bottom: 24px;
    }

    .subName {
        font-size: 20px;
        width: auto;
    }

    .ourApproach {
        font-size: 16px;
    }
}

@media only screen and (max-width: 320px) {

    .subName {
        font-size: 16px;
    }

    .text {
        padding: 4px;
    }
}

@media only screen and (max-height: 600px) {
    .text {
        top: auto;
        bottom: 0;
    }
}