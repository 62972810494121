.ContactBtn {
    position: fixed;
    top: calc(100vh - 100px);
    left: 24px;
    z-index: 1;
    display: flex;
    background-color: rgb(0, 17, 45, 0.3);
    border-radius: 0 4px 4px 0;
    box-shadow: 1px 1px 4px black;

    * {
        color: white;
    }
}

.onSite {
    transform: translateX(calc(-100% + 64px));
    left: 0;
}

.showed {
    transform: translateX(0);
}

.toggler {
    transform: rotate(180deg);
}