.Footer {
    padding: 24px;
    background-color: rgb(15, 27, 50);

    * {
        color: white;
    }

}

.container {
    max-width: 1024px;
    margin: 0 auto;
}

.extra {
    margin-top: 64px;
}

a {
    display: flex;
    align-items: center;

    * {
        margin: 8px;
    }
}

.gridTplCol200 {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    margin-top: 24px;
}

.myName {
    font-size: 24px;
}

iframe {
    width: 100%;
}

.webDeveloper {
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
}