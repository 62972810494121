.wrapperMenu {
    position: fixed;
    z-index: 999;
    
    top: 24px;
    right: 24px;

    width: 3rem;
    height: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    transition: transform 330ms ease-out;

    .line-menu {
        background-color: black;
        // box-sizing: border-box;
        // border: 1px solid white;
        border-radius: 5px;
        width: 100%;
        height: 4px;
        &.half {
            width: 50%;
        }
        &.start {
            transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
            transform-origin: right;
        }
        &.end {
            align-self: flex-end;
            transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
            transform-origin: left;
        }
    }

    &.opened {
        transform: rotate(-45deg);
        .line-menu{
            &.start{
                transform: rotate(-90deg) translateX(3px);
            }
            &.end {
                transform: rotate(-90deg) translateX(-3px);
            }
        }
    }
}

// @media screen and (min-width: 480px) {
//     .wrapperMenu {
//         top: 3rem;
//         right: 4rem;
//     }
// }

// @media screen and (min-width: 800px) {
//     .wrapperMenu {
//         right: 6rem;
//     }
// }

@media screen and (max-height: 480px) and (orientation : landscape) {
    .wrapperMenu {
        // top: 1rem;
        // right: 1rem;
        width: 1.5rem;
        height: 1.5rem;
    }
}
